import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  Title,
  SubHeading,
  BlogTitle,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-audit-template.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"


function ContentAuditDocument({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Audit - Template" 
       description="This document includes a pre-formatted content audit template, along with a report template and slide deck
        that you can use as it is to present your findings."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-audit-template/">Content audit template - Template</a>
            </li>
          </Breadcrumb>
        </Row>
          <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Content Audit - Template" />
            <SubHeading subheading="This document includes a pre-formatted content audit template, along with a report template and a slide deck that you can use to present your findings." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/content-audit-template" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content Audit Template" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used this template when running content audits for B2B companies of all sizes, from growing startups 
          and scale-ups to large enterprises."/> 
            <TextMedium textMedium="While there is no one-size-fits-all setup when it comes to auditing content and extracting insights from 
            the data you're gathering, this approach has helped up bring clarity and give our customers' content a clear direction, in line with
            their overall brand and marketing strategy." />
          <GumroadForm 
                formName="Content Audit Template"
                redirectPage="https://socontent.gumroad.com/l/content-audit-template"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/content-audit-template"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentAuditDocument